import {
  getRedirectLoginClient,
  type LoginSession,
} from '@nrk/innlogging-web/redirect-login-client';
import * as Sentry from '@sentry/svelte';
import { writable } from 'svelte/store';
import { setUser, trackEvent } from '../../util/snowplow.js';

export const loginClient = getRedirectLoginClient({});

export const loginSessionStore = writable<LoginSession | null>();

export const fetchInitialSession = async () => {
  const session = await loginClient.getSession();
  if (session.isLoggedIn && !!session.user) {
    setUser(session.user?.sub);
  }

  if (session.isLoggedIn && (session.isOffgrid || session.isExpired)) {
    try {
      const refreshedSession = await loginClient.forceRefresh();
      loginSessionStore.set(refreshedSession);
      // We track how many users end up in this state.
      trackEvent({
        action: 'error:session-expired',
      });
      return refreshedSession;
    } catch (error: unknown) {
      Sentry.captureException(error);
      trackEvent({
        action: 'error:session-failure-refresh-init',
      });
      return session;
    }
  }
  loginSessionStore.set(session);
  loginClient.addEventListener('update', onSessionUpdate);
  return session;
};

const onSessionUpdate = (event: CustomEvent<LoginSession>) => {
  const session = event.detail;
  loginSessionStore.set(session);
  if (session.isLoggedIn && !!session.user) {
    setUser(session.user?.sub);
  }
};
