<script lang="ts">
  import { onMount } from 'svelte';
  import RadioInput from './RadioInput.svelte';
  import Angryface from './Smileys/Angryface.svelte';
  import Neutralface from './Smileys/Neutralface.svelte';
  import Partyingface from './Smileys/Partyingface.svelte';
  import Pensiveface from './Smileys/Pensiveface.svelte';
  import Smiling from './Smileys/Smiling.svelte';
  export let group: number;
  export let comment = '';
  let heading: HTMLHeadingElement;

  onMount(() => {
    if (heading != null) {
      heading.focus();
    }
  });
</script>

<h2 tabindex="-1" bind:this={heading}>
  Hva synes du om å gjette på denne måten?
</h2>
<div class="emojiwrapper">
  <RadioInput
    value={0}
    bind:group
    name="feedback-interaction"
    text="Veldig dårlig"
  >
    <Angryface />
  </RadioInput>
  <RadioInput value={1} bind:group name="feedback-interaction" text="Dårlig">
    <Pensiveface />
  </RadioInput>
  <RadioInput value={2} bind:group name="feedback-interaction" text="Greit">
    <Neutralface />
  </RadioInput>
  <RadioInput value={3} bind:group name="feedback-interaction" text="Bra">
    <Smiling />
  </RadioInput>
  <RadioInput
    value={4}
    bind:group
    name="feedback-interaction"
    text="Veldig bra"
  >
    <Partyingface />
  </RadioInput>
</div>

<label class="comment">
  Er det noe mer du vil legge til?
  <textarea
    maxlength="1000"
    placeholder="Skriv her..."
    class="comment"
    bind:value={comment}
  ></textarea>
</label>

<style>
  textarea {
    width: 90%;
    height: 8rem;
    margin-top: 1rem;
    padding: 0.5rem;
    background: rgb(130 130 131 / 50%);
    border: 1.04478px solid rgba(0, 0, 0, 0.2);
    color: white;
  }

  textarea::placeholder {
    color: white;
    opacity: 0.75;
  }

  h2 {
    margin: 1rem;
  }
  h2,
  label {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--white);
  }
  h2:focus {
    box-shadow: none;
  }
  .emojiwrapper {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  label.comment {
    margin-top: 4rem;
  }
</style>
