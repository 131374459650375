<script lang="ts">
  import { toggleFavorites } from '../../../../api/api';
  import { trackEvent } from '../../../util/snowplow';
  import * as TEXTS from '../../../util/texts';
  import Chatbox from '../../Participant/Chatbox/Chatbox.svelte';
  import FavoriteBtn from './FavoriteBtn.svelte';
  import FavoriteSparkle from './FavoriteSparkle.svelte';
  import Star from './Star.svelte';
  import debounce from '../../../util/debounce';
  import { onMount } from 'svelte';

  export let participant: Maskorama.Participant;
  let previousIsFavorite = participant.favorite;
  let isFavorite = participant.favorite;

  export let isLocked = false;

  let showChatbox = false;

  const debouncedToggleFavorites = debounce((id: number, isFav: boolean) => {
    if (previousIsFavorite === isFav) {
      return;
    }
    toggleFavorites(id, isFav);
    previousIsFavorite = isFav;
  }, 500);

  const onFavorite = () => {
    isFavorite = !isFavorite;
    debouncedToggleFavorites(participant.id, isFavorite);
    trackEvent({
      action: isFavorite ? 'add-favorite' : 'remove-favorite',
    });
    trackEvent({
      action: 'interaction',
    });
  };
  let showAnimation = false;
  let speed = 0.7;

  let chatBoxTimeoutRef: number;
  let animationTimeoutRef: number;
  const click = () => {
    if (isLocked) return;

    clearTimeout(chatBoxTimeoutRef);
    clearTimeout(animationTimeoutRef);
    showChatbox = false;
    showAnimation = false;

    onFavorite();
    if (isFavorite) {
      showChatbox = true;
      chatBoxTimeoutRef = setTimeout(() => {
        showChatbox = false;
      }, 3_000);
      showAnimation = true;
      animationTimeoutRef = setTimeout(() => {
        showAnimation = false;
      }, speed * 1000);
    }
  };

  onMount(() => {
    return () => {
      clearTimeout(chatBoxTimeoutRef);
      clearTimeout(animationTimeoutRef);
    };
  });
</script>

{#if showChatbox}
  <div class="chatbox-wrapper">
    <Chatbox
      >{TEXTS.getRevealedText(
        TEXTS.CHATBOX_TYPES.FAVORITE,
        0,
        participant.mediaSet
      )}</Chatbox
    >
  </div>
{/if}

<button
  on:click={click}
  class="favorite-button"
  style="--speed: {speed}s"
  title="Favoritt"
  disabled={isLocked}
  aria-pressed={isFavorite}
>
  <FavoriteSparkle className="favorite-button__svg" />
  <FavoriteBtn className="favorite-button__svg" active={isFavorite} />
  {#if showAnimation}
    {#each Array(8) as _}
      <Star {showAnimation} />
    {/each}
  {:else}
    <Star {showAnimation} empty={!isFavorite} />
  {/if}
</button>

<style>
  .chatbox-wrapper {
    position: fixed;
    z-index: 999;
    left: 50%;
    top: 38%;
    width: 100%;
    max-width: 300px;
    transform: translate(-50%, -50%);
  }
  .favorite-button {
    position: relative;
    display: grid;
    align-items: center;
    cursor: pointer;
    width: 118px;
    touch-action: manipulation;
  }
</style>
