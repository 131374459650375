import { isFavoriteText, isGuessText } from './typeguard';

const favorite = `HURRA!<br />JEG ER DIN FAVORITT`;
const guess = `KANSKJE DET,<br />VENT OG SE!`;
const wrong = `BEDRE LYKKE NESTE GANG.`;

export const correct = [
  `YES! DU ER GOD!`,
  `TO RIKTIGE<br />BRA JOBBA!`,
  `TRE RIKTIGE SVAR.<br />FLAKS?`,
  `FIRE RIKTIGE SVAR.<br />FLINKIS!`,
  `FEM RIKTIGE SVAR.<br />DU ER VILL!`,
  `SEKS RIKTIGE.<br />JUHUU!`,
  `SJU RIKTIGE SVAR<br />HAR DU JUKSA?`,
  `ÅTTE RIKTIGE<br />DU ER EN MESTERDETEKTIV!`,
];

export enum CHATBOX_TYPES {
  'GUESS',
  'FAVORITE',
  'WRONG',
  'CORRECT',
}

export function getRevealedText(
  textType: CHATBOX_TYPES,
  correctAnswers: number,
  mediaSets: Maskorama.MediaSet[]
) {
  if (textType === CHATBOX_TYPES.FAVORITE) {
    const favText = mediaSets.find(isFavoriteText);
    return favText?.value ?? favorite;
  }
  if (textType === CHATBOX_TYPES.GUESS) {
    const guessText = mediaSets.find(isGuessText);
    return guessText?.value ?? guess;
  }
  if (textType === CHATBOX_TYPES.WRONG) {
    return wrong;
  }
  if (textType === CHATBOX_TYPES.CORRECT) {
    return getCorrectText(Math.max(correctAnswers - 1, 0));
  }
}

function getCorrectText(correctAnswers: number) {
  if (correctAnswers > 0 && correctAnswers < 9) {
    return correct[correctAnswers];
  }
  return correct[0];
}
