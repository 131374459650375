<script lang="ts">
  import { fly } from 'svelte/transition';
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkChevronDown, nrkChevronUp } from '@nrk/core-icons';
  import { unmaskedParticipants, unmaskParticipant } from '../../../api/api';
  import { participantState } from '../../util/participantState';
  import { trackEvent } from '../../util/snowplow';
  import * as TEXTS from '../../util/texts';
  import Countdown from '../Countdown/Countdown.svelte';
  import MaskIcon from '../MaskIcon.svelte';
  import Scoreboard from '../Scoreboard/Scoreboard.svelte';
  import Chatbox from './Chatbox/Chatbox.svelte';

  const flyduration = 400;

  export let participant: Maskorama.Participant;
  $: revealed = !!participant.realName;
  $: masked = participant.unmasked === false;
  $: correct = !masked && participant.correct;
  $: wrong =
    !masked &&
    participant.guessedName != undefined &&
    participant.correct === false;
  let showChatbox = false;

  const unmask = async () => {
    trackEvent({
      action: 'unmask-participant',
      label: `participant:${participant.id}`,
    });
    await unmaskParticipant(participant.id);
    showChatbox = true;
  };
  $: points = getPoints($unmaskedParticipants);

  const getPoints = (unmasked: Maskorama.Participant[]) => {
    return unmasked.reduce((acc, participant) => {
      if (participant.unmasked && participant.correct) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  $: if (!participant.unmasked) {
    trackEvent({
      action: 'view-unrevealed',
      label: `participant:${participant.id}`,
    });
  }

  $: if (showChatbox === true) {
    setTimeout(() => {
      showChatbox = false;
    }, 3_000);
  }

  const toggleContent = () => {
    hideContent = !hideContent;
    setTimeout(() => {
      document.querySelector<HTMLButtonElement>('.toggle')?.focus();
    }, flyduration + 100);
    trackEvent({
      action: hideContent
        ? 'unmasked-participant:hide'
        : 'unmasked-participant:show',
      label: `participant:${participant.id}`,
    });
  };

  let hideContent = false;
</script>

{#if !hideContent}
  <div
    class="content-wrapper"
    transition:fly={{ y: 400, duration: flyduration }}
  >
    <div class="content" class:masked class:revealed class:correct class:wrong>
      <div class="countdown-wrapper">
        <Countdown largefixed={false} />
      </div>
      <button
        class="toggle"
        on:click={toggleContent}
        class:masked
        class:revealed
        class:correct
        class:wrong
        aria-label="Skjul innhold som ligger over videoen"
      >
        <span class="icon-container">
          {@html nrkChevronDown}
        </span>
      </button>
      <div class="revealed-banner">Avslørt</div>
      <div class="info">
        {#if masked}
          <button type="button" class="reveal-button" on:click={unmask}>
            <MaskIcon />
            <strong>Trykk her</strong>
            <div>og se hvem som ble avslørt!</div>
          </button>
        {:else if correct}
          {#if showChatbox}
            <Chatbox>
              {@html TEXTS.getRevealedText(
                TEXTS.CHATBOX_TYPES.CORRECT,
                points,
                participant.mediaSet
              )}
            </Chatbox>
          {/if}
          <!-- svelte-ignore a11y_autofocus -->
          <h2 autofocus tabindex="-1">
            {participant.maskName} var {participant.realName}
          </h2>
          <p>Du gjettet riktig!</p>
        {:else if wrong}
          {#if showChatbox}
            <Chatbox>Bedre Lykke neste gang!</Chatbox>
          {/if}
          <!-- svelte-ignore a11y_autofocus -->
          <h2 autofocus tabindex="-1">
            {participant.maskName} var {participant.realName}
          </h2>
          <p>Du gjettet {participant.guessedName}</p>
        {:else}
          <!-- svelte-ignore a11y_autofocus -->
          <h2 autofocus tabindex="-1">
            {participant.maskName} var {participant.realName}
          </h2>
          <p>Du gjettet ikke</p>
        {/if}
        {#if !masked}
          <Scoreboard
            participantID={participant.id}
            participantState={participantState(participant)}
          />
        {/if}
      </div>
    </div>
  </div>
{:else}
  <button
    class="toggle hidden"
    on:click={toggleContent}
    class:masked
    class:revealed
    class:correct
    class:wrong
    aria-label="Vis innhold"
  >
    <span class="icon-container">
      {@html nrkChevronUp}
    </span>
  </button>
{/if}

<style>
  .content-wrapper {
    height: 100%;
    display: grid;
    place-items: center;
  }

  button {
    animation: unset;
  }

  .icon-container {
    height: 24px;
    width: 24px;
  }

  .toggle {
    height: 80px;
    width: 50px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    padding-top: 0.4rem;
    margin-bottom: -3.6rem;
    margin-left: 0.8rem;
    align-self: flex-start;
  }
  .toggle.correct {
    background: var(--green);
  }
  .toggle.wrong {
    background: var(--orange);
  }
  .toggle.revealed:not(.wrong):not(.correct) {
    background: var(--purple);
  }
  .toggle.masked {
    background: var(--red);
  }
  .toggle.hidden {
    position: absolute;
    left: 0.5rem;
    height: 60px;
    margin-bottom: 0rem;
    transform: translateX(-50%);
    bottom: 0;
    border-radius: 10px 10px 0 0;
    animation: pulse 3s infinite ease-in;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .content {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 0.3rem;
    align-items: center;
    justify-content: flex-end;
  }
  .countdown-wrapper {
    position: fixed;
    top: 5rem;
    right: 1rem;
  }
  h2,
  p {
    align-self: flex-start;
  }
  h2 {
    font-size: 1rem;
    margin-bottom: 0.2rem;
    margin-top: 3rem;
    overflow: hidden;
    max-width: 18rem;
    text-overflow: ellipsis;
  }
  p {
    font-size: 1.2rem;
  }

  .content {
    --reveal-border: var(--purple);
    --heading: var(--red);
    --reveal-bg: var(--black);
  }

  .content.masked {
    --reveal-bg: linear-gradient(0deg, var(--red) 50.14%, var(--purple) 100%);
    --reveal-border: var(--purple);
  }

  .content.correct {
    --reveal-border: var(--green);
    --heading: var(--green);
  }

  .content.wrong {
    --reveal-border: var(--orange);
    --heading: var(--orange);
  }

  .revealed-banner {
    transform: rotate(-5deg);
    left: -2rem;
    width: calc(100% + 4rem);
    text-transform: uppercase;
    max-height: 50%;
    border-top: 3.5px solid;
    border-top-color: currentcolor;
    border-bottom: 5px solid;
    border-bottom-color: currentcolor;
    border-color: var(--reveal-border);
    background: var(--black);
    font-style: italic;
    font-size: 41px;
    font-weight: 850;
    line-height: 70px;
    text-align: center;
  }

  .revealed-banner::after {
    content: '';
    display: block;
    width: 130%;
    left: -15%;
    height: var(--participant-page-height);
    background: var(--reveal-bg);
    position: absolute;
    margin-top: 3.5px;
  }

  .info {
    bottom: 5%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-inline: 1rem;
    max-width: var(--content-width);
  }

  .masked .info {
    height: 50%;
    position: unset;
    bottom: auto;
    padding: 0;
  }

  .info > * {
    z-index: 1;
  }

  .info h2 {
    color: var(--heading);
  }

  .reveal-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--black);
    height: 100%;
    width: 100%;
    letter-spacing: 0.025em;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .reveal-button > * {
    display: block;
    max-width: 12rem;
  }
</style>
