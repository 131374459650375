import { getWeek, getYear } from 'date-fns';
import { jwtDecode } from 'jwt-decode';
import { getAccessToken } from '../lib/components/Login/util.js';
import { getSessionUUID } from '../lib/util/sentry.js';

let fid: string | null = null;

function getAppId() {
  const today = new Date();
  return `maskorama-${getYear(today)}-${getWeek(today)}`;
}

const getAnonymousData = () => {
  try {
    const sessionUUID = getSessionUUID();

    return {
      app_version: import.meta.env.VITE_FULL_VERSION,
      sessionId: sessionUUID,
    };
  } catch {
    return {};
  }
};

const getUserData = async () => {
  try {
    const token = await getAccessToken();
    const decoded = jwtDecode(token ?? '') as {
      'nrk/age'?: string;
      'nrk/user/vl'?: number;
    };
    return {
      age: decoded['nrk/age'],
      verification_level: decoded['nrk/user/vl'],
    };
  } catch {
    return {};
  }
};

export async function postFeedback({
  votesFeedback,
  category,
}: {
  votesFeedback: number;
  category: string;
}) {
  try {
    const userData = await getUserData();
    const anonymousData = getAnonymousData();
    const result = await fetch(
      `${import.meta.env.VITE_FEEDBACK_URL}/api/v1/feedback/${getAppId()}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          thumb: false,
          extraData: {
            ...userData,
            ...anonymousData,
            votesFeedback,
            category,
          },
        }),
      }
    );
    if (result.ok) {
      const resultJson = await result.json();
      fid = resultJson.fid;
      return fid;
    }
    return null;
  } catch (_error) {
    return null;
  }
}

export async function updateFeedback(comment: string) {
  try {
    if (fid == null) {
      throw new Error('fid is not defined');
    }
    const result = await fetch(
      `${import.meta.env.VITE_FEEDBACK_URL}/api/v1/feedback/${getAppId()}/${fid}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
          comment,
        }),
      }
    );
    if (result.ok) {
      return await result.json();
    }
    return null;
  } catch (_error) {
    return null;
  }
}
