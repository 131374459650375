import {
  flushBuffer,
  newTracker,
  trackPageView as snowplowTrackPageView,
  trackStructEvent,
  type BrowserTracker,
} from '@snowplow/browser-tracker';
import type { ActionReturn } from 'svelte/action';
import { isFlagSetInLocalStorage } from './debuflags.js';

export const SNOWPLOW_APP_ID = 'no.nrk.delta.maskorama';
const TRACKER_ID = 'delta-tracker-maskorama';
const HOST = import.meta.env.VITE_SNOWPLOW_URL;

let snowplowTracker: BrowserTracker | undefined = undefined;

export function initializeSnowPlowTracker() {
  snowplowTracker = newTracker(TRACKER_ID, HOST, {
    appId: SNOWPLOW_APP_ID,
    postPath: '/nrk/wd6',
    eventMethod: 'beacon',
    encodeBase64: import.meta.env.DEV ? false : true, //for debugging
    bufferSize: 5,
    respectDoNotTrack: true,
    stateStorageStrategy: 'cookieAndLocalStorage',
  });
}

export const setUser = (userId: string | undefined) => {
  if (userId) {
    snowplowTracker?.setUserId(userId);
  }
};

export const trackPageView = (title: string) => {
  if (isFlagSetInLocalStorage('pin-debug-analytics')) {
    // eslint-disable-next-line no-console
    console.log('PAGE_VIEW: ' + JSON.stringify(title));
  }
  snowplowTrackPageView(
    {
      title,
      context: getContext(),
    },
    [TRACKER_ID]
  );
  debounceFlushEventsBuffer();
};

type ImpressionEvent = {
  action: `impression:${string}`;
};

type AnalyticsEvent = ImpressionEvent | { action: string };

type Event = Omit<Parameters<typeof trackStructEvent>[0], 'category'> &
  AnalyticsEvent;

export const trackEvent = (event: Event) => {
  if (isFlagSetInLocalStorage('pin-debug-analytics')) {
    // eslint-disable-next-line no-console
    console.log('EVENT: ' + JSON.stringify(event));
  }
  trackStructEvent(
    {
      ...event,
      category: 'PIN',
      context: getContext(),
    },
    [TRACKER_ID]
  );
  debounceFlushEventsBuffer();
};

const getContext = () => {
  const context: Array<{ schema: string; data: Record<string, string> }> = [
    {
      schema: 'iglu:no.nrk/service/jsonschema/2-0-0',
      data: {
        id: 'nrkno',
        environment: import.meta.env.VITE_APP_ENV,
      },
    },
  ];
  if (snowplowTracker?.getUserId()) {
    context.push({
      schema: 'iglu:no.nrk/nrk-user/jsonschema/1-0-0',
      data: {
        id: snowplowTracker?.getUserId() as unknown as string,
      },
    });
  }

  return context;
};

let debouncedFlushBufferTimeoutId: number | undefined;
const debounceFlushEventsBuffer = () => {
  clearTimeout(debouncedFlushBufferTimeoutId);
  const timeoutMs = randomNumberBetween(3_000, 10_000);
  debouncedFlushBufferTimeoutId = window.setTimeout(() => {
    flushBuffer();
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.log('Flushed buffer');
    }
  }, timeoutMs);
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log(`flushBuffet scheduled in ${timeoutMs}`);
  }
};

function randomNumberBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    flushBuffer();
  }
});

const impressioned: string[] = [];
/*
  Svelte directive, can be used by adding `use:directive={someId}`
  to a html-element that you want to track impressions for
*/
export function impression(
  element: HTMLElement,
  label: string
): ActionReturn<{ label: string }> {
  const intersectionObserverSupported =
    !!('IntersectionObserver' in window) &&
    !!('IntersectionObserverEntry' in window) &&
    !!('intersectionRatio' in window.IntersectionObserverEntry.prototype);

  const requiredViewTime = 3_000;

  if (intersectionObserverSupported) {
    let timeoutRef: number;

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !impressioned.includes(label)) {
          timeoutRef = window.setTimeout(() => {
            impressioned.push(label);
            trackEvent({ action: `impression:${label}` });
          }, requiredViewTime);
        }

        if (!entry.isIntersecting) {
          clearTimeout(timeoutRef);
        }
      });
    };

    const observer = new IntersectionObserver(callback, {
      root: document.getElementById('root'),
      rootMargin: '0px',
      threshold: 0.8,
    });

    observer.observe(element);

    return {
      destroy() {
        observer.disconnect();
      },
    };
  }
  return {};
}
